// @flow
import React from 'react';
import { Link } from 'gatsby';
import styles from './Menu.module.scss';
import EmailListFormFrontPage from '../../EmailListFormFrontPage';

type Props = {
  menu: {
    label: string,
    path: string
  }[]
};

const Menu = ({ menu }: Props) => (
  <nav className={styles['menu']}>
    <ul className={styles['menu__list']}>
      {menu.map((item) => (
        <li className={styles['menu__list-item']} key={item.path}>
          <Link
            to={item.path}
            className={styles['menu__list-item-link']}
            activeClassName={styles['menu__list-item-link--active']}
          >
            {item.label}
          </Link>
        </li>
      ))}

      <li className={styles['menu__list-item']} key="/experience">
        <Link
          to="/experience"
          className={styles['menu__list-item-link']}
          activeClassName={styles['menu__list-item-link--active']}
        >
          Experience
          </Link>
      </li>

      <EmailListFormFrontPage />
    </ul>
  </nav>
);

export default Menu;
